<template>
  <div>
        <a-table
            :columns="columns"
            :row-key="record => record.id"
            :data-source="myDate"
            :pagination="false"      
            bordered
        >
            <p slot="expandedRowRender" slot-scope="record" style="margin: 0;">
                <template >                    
                    <div class="ddic" v-if="record.total_score>0">
                        <a-table :columns="columnes" :pagination="false" :data-source="record.acts_shipDetail" :row-key="record => record.id" bordered>
                        </a-table>
                    </div>
                </template>                            
            </p>
            <template slot="total_score" slot-scope="total_score"> {{ myOrder(total_score) }}</template>
            <template slot="order" slot-scope="order"> {{ myOrder(order) }}</template>                       
        </a-table>
           
  </div>
</template>

<script>
import * as moment from "moment/moment";

const columns = [
  {
    title: '月份',
    dataIndex: 'months',   
    scopedSlots: { customRender: 'months' },  
  },    
  {
    title: '鄉鎮',
    dataIndex: 'area',  
    scopedSlots: { customRender: 'area' },    
  }, 
  {
    title: '累積學分',
    dataIndex: 'total_score',   
    scopedSlots: { customRender: 'total_score' },  
  },
  { 
    title: '名次', 
    dataIndex: 'order', 
    scopedSlots: { customRender: 'order' } ,
  },     

];


const columnes = [
  {
    title: '縣市',
    dataIndex: 'citys',
    scopedSlots: { customRender: 'citys' },    
  },     
  {
    title: '鄉鎮',
    dataIndex: 'villages',
    scopedSlots: { customRender: 'villages' },    
  },
  {
    title: '村里',
    dataIndex: 'towns',
    scopedSlots: { customRender: 'towns' },    
  },              
  {
    title: '累積學分',
    dataIndex: 'score',
    scopedSlots: { customRender: 'score' },    

  },
]; 

export default {
  name: "Actshipreport",
  props: {
    myDate : Array,
  },
  data() {
    return {
      columns,
      columnes,
      currentTime:moment(),
    };
  }, 
  methods: {
    getdate(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },

    catImg(imgs) {
      return require(`../../assets/img/${imgs}`);
    },
    myOrder(val){
        if(val>0){
            return val
        }else{
            return ''
        }
    }
    
  },
  created() {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ddic {
  border-radius: 10px;
  box-shadow: 1px 1px 15px -5px rgb(206, 204, 204);
  transition: all 0.3s ease;
}

</style>





