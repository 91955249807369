<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" ref="MyBar" /> 
     <Myreportshipscore />     
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="test" />
    <div class="applyfooter" v-if="windowsize.width<=991">
    </div>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions,mapMutations} from 'vuex';
import router from '../router'
import * as moment from "moment/moment";
import Myreportshipscore from './Myreportshipscore.vue'
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'

import 'moment/locale/zh-tw';

export default {
  name: 'Reports',
  components: {
    Menubar,
    Footbar,
    Myreportshipscore
  }, 
  props: {
    msg: String
  },
  data() {
    return {
      windowsize: {
            width: 0,
            height: 0
        }, 
      page: 1,       
      classtheme:null,
      iconLoading:false,
      list:[],     
      uId:'',  
    };
  }, 
  computed: {
    ...mapState("account", ["status", "user","linecheck"]),
  },   
  mounted(){
        if(this.user==null){
          router.replace('/')
        }else{
          this.checktoken()   
        } 
        this.$refs["MyBar"].closebar(false); 
  },
  methods: {
    ...mapActions('account',['logout','checktoken']), 
    ...mapMutations('account',['setpage','getLinecheck']),   
    query(rs){
      this.classtheme = rs
    },

    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },
    changedYear(e){
        console.log(e)
    },            

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
  },      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}

</style>

