<template>
  <div class="about" >
    <a-spin tip="資料載入中..." :spinning="loading">
    <div class="container text-left">
      <b-form-group style="margin-top: 0.3rem;" >
        <a-month-picker placeholder="選擇月份" style="margin-right: 0.3rem;"  @change="onChange" />
        <b-form-select v-model="selectedVillage" style="width: 160px;height: 2.1rem;">           
            <option :value="localAreaAll">全部資料</option>
            <option v-for="item in list" :value="item.myaddress" :key="item.village">
            {{ item.village }}
            </option>
        </b-form-select>
        <b-button variant="info" @click="query()" :disabled="selectedVillage=='' || defaultDate=='' " style="width: 100px;height: 2.1rem;" >查詢</b-button>
      </b-form-group>
      <div class="text-center" style="padding:0.5rem;max-width: 1024px;" v-if="rsMyData!==null" >
        <Actshipreport v-if="rsMyData.length>0" :myDate="rsMyData" ref="con2"/>
      </div>      
    </div> 
    </a-spin>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import * as moment from "moment/moment";
import Actshipreport from './panel/Actshipreport.vue'
import { reportService } from '../_services'
import 'moment/locale/zh-tw';

const queryData = params => {
  return reportService.getAddress(params.id) 
};
const queryMyData = params => {
  return reportService.getShip(params) 
};

export default {
  name: 'Myreportshipscore',
  components: {
    Actshipreport,
  }, 
  props: {
    msg: String
  },
  data() {
    return {  
      useid:0,
      defaultDate: '' , 
      selectedVillage: '',
      rsMyData: [],
      list:null,
      loading: false,
      localArea: '',   
      localAreaAll: '',
    };
  }, 
  computed: {
    ...mapState("account", ["status", "user","linecheck"]),
  },   
  mounted(){
        this.localArea = process.env.VUE_APP_AREA
        this.localAreaAll = process.env.VUE_APP_AREAAll
        if(this.user!==null){  
          this.useid = this.user.data.user.id 
          const now = new Date()
          const today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate())).format("YYYY-MM-DD") 
          this.yDate = moment(today).format("YYYY") 
          this.fetch()
        }
  },
  methods: {
    
    onChange(date, dateString) {
      this.defaultDate = dateString;
      console.log(date, dateString);
    }, 

    query(){
        if(this.selectedVillage!=='' && this.defaultDate!==''){
            this.loading = true;
            this.fetch2()
        }
    },
    fetch2(      
      params = {
        date:this.defaultDate,           
        address:this.selectedVillage          
      }) {
        queryMyData({
        ...params,
      }).then(({ data }) => {
            this.rsMyData=data.data
            this.loading = false
      });
    },    
    
    fetch(
      params = {
          id:this.localArea,              
      }) {
      queryData({
        ...params,
      }).then(({ data }) => {
            this.list=data.data
      });
    },    

  },      
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 0px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}
.contantdiv{
  margin: 5px;
  overflow-x: hidden;
}

</style>

